import React from "react";
import './Popup.css'
import fb from '../../image/Fiorelli_facebook.svg'
import email from '../../image/Fiorelli_email.svg'
import downnload from '../../image/Fiorelli_Jpeg.svg'
import close from '../../image/Icons/Fiorelli_close.svg'

import SendEmail from "./SendEmail";
import { WEBSITE_ROOT } from '../../constants'

function SendPostCard({sendEmail, shareOnFacebookUrl, downloadImageUrl, openCategoriesStep}) {
    return(
        <div>
            <div className="modal" id="PostCardModal" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src={close} alt=""/>
                        </button>
                        <div className="modal-body">
                            <h2>
                                ваше <span>тостіні</span> можна
                            </h2>
                            <div className="social_card">
                                <div className="social_link_item">
                                    <a target="_blank" href={shareOnFacebookUrl} className="fb-xfbml-parse-ignore">
                                        <img src={fb} alt=""/>
                                        <p className='text_sm'>опублікувати у facebook</p>
                                    </a>
                                </div>
                                <div style={{cursor: 'pointer'}} data-toggle="modal" data-target="#sendEmail"  className="social_link_item">
                                    <img src={email} alt=""/>
                                    <p className='text_sm'>відправити поштою</p>
                                </div>
                                <div className="social_link_item">
                                    <a href={downloadImageUrl}>
                                      <img src={downnload} alt=""/>
                                      <p className='text_sm'>скачати <br/> JPEG</p>
                                    </a>
                                </div>
                            </div>
                            <div onClick={openCategoriesStep} className='button' data-dismiss="modal" aria-label="Close">
                                Створити ще
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SendEmail
              sendEmail={sendEmail}
            />
        </div>
    )
}
export default SendPostCard
