import React from "react";

function TabsFontColor(props) {
    let {active, tabsActiveContentHandler, foregroundColors } = props
    console.log("active__foregroundColor", active)
    console.log("foregroundColor", foregroundColors)

    return(
        <div className='d-flex flex-wrap justify-content-center p-3 pt-lg-4'>
            {foregroundColors&&foregroundColors.map((item, idx) => <div onClick={()=>tabsActiveContentHandler(item, "foregroundColor")} className={'item font_color_item ' + (active===item ? 'active' : '')} style={{ fill: item}} key={idx}>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 38 38" enableBackground="new 0 0 38 38" >
                    <g>
                        <path fill={item} d="M36.1,7.3c-1.1-1.1-2.9-1.2-4.1,0l-7.2,7.3L23.4,11c-0.3-0.8-0.5-1.4-0.7-1.9c-0.2-0.5-0.5-1-0.8-1.4
                            c-0.3-0.5-0.8-0.9-1.3-1.2c-0.6-0.3-1.2-0.5-2-0.5c-0.8,0-1.5,0.2-2,0.5c-0.5,0.3-1,0.7-1.3,1.2c-0.3,0.5-0.6,1-0.8,1.7L7.7,26.3
                            c-0.3,0.6-0.4,1.1-0.6,1.5C7.1,28.3,7,28.7,7,29.1c0,0.8,0.3,1.5,0.9,2.1C8.5,31.7,9.2,32,10,32c0.9,0,1.7-0.3,2.1-0.9
                            c0.4-0.5,0.8-1.3,1.2-2.6l0.9-2.5h0.2l-1,4.6c-0.1,0.3,0,0.6,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1l5-2
                            c0.1,0,0.2-0.1,0.3-0.2l3-3.3h0.1l1,2.5c0.2,0.4,0.3,0.8,0.5,1.3c0.2,0.5,0.5,0.9,0.7,1.2c0.2,0.3,0.6,0.6,0.9,0.8
                            c0.4,0.2,0.8,0.3,1.3,0.3c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-0.6-0.2-1.5-0.8-2.8l-2.2-5.3l8.8-9.5
                            C37.3,10.2,37.3,8.4,36.1,7.3z M13.8,24.4c-0.3,0-0.6,0.2-0.7,0.5l-1.1,3c-0.5,1.4-0.8,2-1,2.2c-0.1,0.1-0.2,0.3-0.9,0.3
                            c-0.4,0-0.8-0.1-1.1-0.5c-0.3-0.3-0.5-0.6-0.5-0.9c0-0.2,0-0.5,0.1-0.8c0.1-0.3,0.3-0.8,0.5-1.4l6-15.4l0.6-1.6
                            c0.2-0.6,0.5-1,0.7-1.4C16.7,8.3,17,8,17.3,7.8c0.7-0.4,1.9-0.4,2.6,0c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.4,0.4,0.7,0.6,1.1
                            c0.2,0.5,0.4,1.1,0.7,1.8l1.7,4.2l-2.2,2.2l-2.3-6.3c-0.1-0.3-0.4-0.5-0.7-0.5c0,0,0,0,0,0c-0.3,0-0.6,0.2-0.7,0.5l-3.5,9.8
                            c-0.1,0.2-0.1,0.5,0.1,0.7c0.1,0.2,0.4,0.3,0.6,0.3h2.1l-2,2.1H13.8z M18.7,20.8h-2.5l2.4-6.8l1.8,5L18.7,20.8z M15.8,26.8l1.8,1.7
                            l-2.4,1L15.8,26.8z M19,27.8l-2.6-2.5l5.4-5.5c0,0,0,0,0,0l3.4-3.4l0,0c0,0,0,0,0,0l4.7-4.8l2.2,2.1l-6,6.5c0,0,0,0,0,0l0,0
                            L19,27.8z M28.9,29c0,0.3-0.2,0.7-0.5,1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.3
                            c-0.1-0.1-0.3-0.4-0.5-0.9c-0.2-0.5-0.4-0.9-0.5-1.2l-1.1-3c0-0.1-0.1-0.2-0.2-0.2l2.4-2.6l1.9,4.7C28.8,28.2,28.9,28.8,28.9,29z
                             M35.1,10.3l-2.1,2.2l-2.1-2l2.2-2.2c0.5-0.6,1.4-0.5,2,0C35.6,8.9,35.6,9.8,35.1,10.3z"/>
                        <rect fill="none" width="38" height="38"/>
                    </g>
                </svg>
            </div> )}
        </div>
    )
}
export default TabsFontColor