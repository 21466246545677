import React from "react";
import logo from '../../../image/logo.svg'

function Logo({onClick}) {
    return(
        <div className='Logo'>
          <div onClick={onClick} style={{display: 'inline-block', cursor: 'pointer'}}>
            <img src={logo} alt=""/>
          </div>
        </div>
    )
}
export default Logo
