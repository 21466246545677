import React, {Fragment} from "react";

import { IMAGES_ROOT } from '../../../../constants'


function TabsBgImage(props) {
    let {active, tabsActiveContentHandler, backgroundImages } = props
    console.log()
    return(
        <div className='tabs_width_mobile px-2'>
            { backgroundImages && backgroundImages.map((item, idx) => <div onClick={()=>tabsActiveContentHandler(item, "backgroundImageUrl")} className={'item bg_image ' + (active===item ? 'active' : '')} key={idx}>
                <img src={IMAGES_ROOT + item} alt=""/></div>)}
        </div>
    )
}
export default TabsBgImage
