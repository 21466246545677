import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import FullPageGreetingScreen from "./screens/FullPageGreetingScreen"
import WizardScreen from "./screens/WizardScreen.js"

const App = () => {
  return (
    <div className='App'>
      <WizardScreen />
    </div>
  )
};

const FullApp = () => {
  return (
    <Switch>
      <Route exact path="/render_greetings" component={FullPageGreetingScreen} />
      <Route component={App} />
    </Switch>
  )
}

export default FullApp
