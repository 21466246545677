import React from "react";
import './Loader.css'
import gif from '../../../image/Fiorelli_creation.gif'

function Loader(){
    return(
        <div className='loader'>
            <img src={gif} alt="loading..."/>
        </div>
    )
}

export default Loader