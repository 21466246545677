import { saveEditorData } from '../utils'

export default function editor(state = {}, action) {
  let newState;

  switch (action.type) {
    case 'INITIALIZE_EDITOR':
      if (!action.editorData) {
        return {...state}
      }
      return {
        ...state,
        category_id: action.editorData.category_id,
        title: action.editorData.title
      }

    case 'SET_CATEGORY_ID':
      newState = {
        ...state,
        category_id: action.category_id,
      }

      break

    case 'SET_TITLE':
      newState = {
        ...state,
        title: action.title
      }

      break

    default:
      return state
  }

  saveEditorData(newState)

  return newState
}
