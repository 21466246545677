import React from "react";

const fonts = ['sangBleu_sunrise_regular', 'montserrat_lternates_regular', 'playfair_display_regular', 'pt_serif_regular']
function TabsFont(props) {
    let {active, tabsActiveContentHandler, font } = props

    return(
        <div className='tabs_width_mobile px-2'>
            {fonts.map((item, idx) => <div onClick={()=>tabsActiveContentHandler(item, 'font')} className={item +' item item_fonts ' + (active===item ? 'active ' : '')} key={idx}>Шрифтіні <br/> для тостіні</div>)}
        </div>
    )
}
export default TabsFont