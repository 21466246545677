export const findCategoryById = (categories, category_id) => {
  return categories.find((category) => category.id === category_id)
}

export const saveEditorData = (data) => {
  console.log("saveEditorData: data: ", data)
  localStorage.setItem('editor', JSON.stringify(data))
}

export const fetchEditorData = () => {
  const s = localStorage.getItem('editor')
  return s && JSON.parse(s)
}

// currently not used
const saveEditorDataProperty = (property_name, value) => {
  const data = fetchEditorData() || {}
  data[property_name] = value
  localStorage.setItem('editor', JSON.stringify(data))
}

export const sample = (array) => {
  return array[Math.floor(Math.random() * array.length)];
}

export const serializeToQueryString = (hash) => {
  const str = [];
  for (let k in hash)
    if (hash.hasOwnProperty(k)) {
      str.push(encodeURIComponent(k) + "=" + encodeURIComponent(hash[k]));
    }
  return str.join("&");
}
