import React from 'react';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { hydrate } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers/index'
import { API_ROOT } from './constants';
import axios from 'axios';
import './index.scss';
import { fetchEditorData } from './utils'

import 'bootstrap/dist/js/bootstrap.min';

const store = createStore(reducer);

axios.get(`${API_ROOT}/data.json`).then(response => {
	console.log("data: ", response.data)
  store.dispatch({type: 'INITIALIZE_DATA', data: response.data})
})

const editorData = fetchEditorData()
store.dispatch({type: 'INITIALIZE_EDITOR', editorData: editorData})

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
