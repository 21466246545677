import React, {Component, Fragment} from "react";
import './EditorScreen.scss'
import Tabs from "../../components/UI/Tabs/Tabs";
import Greeting from "../../components/UI/Postcard/Postcard";
import TabsContent from "../../components/UI/Tabs/TabsContent";

import buble_tostini from "../../image/buble/Tostini.svg";
import SendPostCard from "../../components/popup/SendPostCard";
import Button from "../../components/UI/Button/Button";
import Loader from "../../components/UI/Loader/Loader";
import { sample, serializeToQueryString } from '../../utils'
import { API_ROOT } from '../../constants'
import axios from 'axios'
import ThanksPopup from "../../components/popup/thanksPopup";

export default class EditorScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      backgroundImageUrl: this.getDefaultBackgroundImageUrl(props),
      backgroundColor: this.getDefaultBackgroundColor(props),
      foregroundColor: this.getDefaultForegroundColor(props),
      font: this.getDefaultFont(),
      text: this.getRandomText(props),
      activeTabs: 1,
      activeTabsContent:'',
      showSuccess:false,
      load: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.title !== prevProps.title) {
      this.setState({title: this.props.title})
    }
  }

  getRandomText = (props) => {
    props = props || this.props

    const newText = sample(props.pledges)
    if (this.state && this.state.text === newText) {
      return this.getRandomText(props)
    }

    return newText
  }

  regenerateText = () => {
    let handlingLoad = () =>{this.setState({load: !this.state.load})}
    handlingLoad()
    const text = this.getRandomText()
    this.setState({text: text})

    setTimeout(handlingLoad, 1000)
  }

  getDefaultFont = () => {
    return 'sangBleu_sunrise_regular'
  }

  getDefaultBackgroundColor = (props) => {
    props = props || this.props

    return props.backgroundColors[0]
  }

  getDefaultForegroundColor = (props) => {
    props = props || this.props

    return props.foregroundColors[1]
  }

  getDefaultBackgroundImageUrl = (props) => {
    props = props || this.props

    return props.backgroundImages[0]
  }

  tabsChangeHandler = (active) => {
    this.setState({
      activeTabs: active
    })
  };

  tabsActiveContentHandler = (active, type) => {
    this.setState({
      activeTabsContent: active
    })

    if (type === 'backgroundColor' ){
      this.setState({
        backgroundColor: active
      })
    }if(type === 'backgroundImageUrl' ){
      this.setState({
        backgroundImageUrl: active
      })
    }if(type === 'foregroundColor' ) {
      this.setState({
        foregroundColor: active
      })
      console.log('tabsActiveContentHandler' , this.state.foregroundColor)
    }else{
      this.setState({
        font: active
      })
    }
  }

  changeTitle = (title) => {
    this.setState({title})
  }

  changeText = (text) => {
    this.setState({text})
  }

  sendEmail = (receiverEmail, senderName) => {
    this.setState({load: true})
    this.unsafeRemoveDialogClassFromBody()
    axios.post(`${API_ROOT}/send_email.json`, {
      category_id: this.props.category.id,
      background_image_url: this.state.backgroundImageUrl,
      background_color: this.state.backgroundColor,
      foreground_color: this.state.foregroundColor,
      font: this.state.font,
      title: this.state.title,
      text: this.state.text,
      receiver_email: receiverEmail,
      sender_name: senderName
    }).then(() => {
      this.setState({load: false})
      this.showSuccessHandler()
    }).catch(() => {
      alert("Сталася помилка. Спробуйте, будь ласка, пізніше")
    })
  }

  showSuccessHandler = () =>{
    this.setState({
      showSuccess: !this.state.showSuccess
    })
  }

  shareOnFacebook = () => {
    this.setState({load: true})
    this.unsafeRemoveDialogClassFromBody()
    axios.post(`${API_ROOT}/build_image.json`, {
      category_id: this.props.category.id,
      background_image_url: this.state.backgroundImageUrl,
      background_color: this.state.backgroundColor,
      foreground_color: this.state.foregroundColor,
      font: this.state.font,
      title: this.state.title,
      text: this.state.text
    }).then(({data}) => {
      this.setState({load: false})
      const { url_for_facebook_share } = data.image_build
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url_for_facebook_share}&src=sdkpreparse`
      window.location = facebookUrl
    }).catch(() => {
      alert("Сталася помилка. Спробуйте, будь ласка, пізніше")
    })
  }

  getShareOnFacebookUrl = () => {
    const imageBuildData = {
      category_id: this.props.category.id,
      background_image_url: this.state.backgroundImageUrl,
      background_color: this.state.backgroundColor,
      foreground_color: this.state.foregroundColor,
      font: this.state.font,
      title: this.state.title,
      text: this.state.text
    }
    const queryString = serializeToQueryString(imageBuildData)

    return `${API_ROOT}/share_on_facebook?${queryString}`
  }

  unsafeRemoveDialogClassFromBody = () => {
    document.body.classList.remove('modal-open')

    // remove all elements with css class "modal-backdrop"
    const elements = document.getElementsByClassName('modal-backdrop');
    while(elements[0]) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  getDownloadImageUrl = () => {
    const imageBuildData = {
      category_id: this.props.category.id,
      background_image_url: this.state.backgroundImageUrl,
      background_color: this.state.backgroundColor,
      foreground_color: this.state.foregroundColor,
      font: this.state.font,
      title: this.state.title,
      text: this.state.text
    }
    const queryString = serializeToQueryString(imageBuildData)
    return `${API_ROOT}/download_image?${queryString}`
  }

  render() {
    let {activeTabs, showSuccess} = this.state;

    const greetingProps = {
      backgroundImageUrl: this.state.backgroundImageUrl,
      backgroundColor: this.state.backgroundColor,
      foregroundColor: this.state.foregroundColor,
      font: this.state.font,
      title: this.state.title,
      text: this.state.text,
      changeTitle: this.changeTitle,
      changeText: this.changeText
    };

    return (
      <section className='editor'>
        {this.state.load || this.props.loading ? <Loader/>
            :
            <Fragment>
              <div className="container p-0">
                <h2>застилізуйте власноруч своє <span> <img className='hoverImage' src={buble_tostini}
                                                            alt=""/> тостіні</span></h2>
                <div className="editor-wrapper row">
                  <div className="col-lg-2 p-0">
                    <Tabs activeTabs={activeTabs} tabsChangeHandler={this.tabsChangeHandler}/>
                  </div>
                  <div className="col-lg-2 p-0">
                    <TabsContent
                        activeTabs={activeTabs}
                        foregroundColors={this.props.foregroundColors}
                        backgroundImages={this.props.backgroundImages}
                        font={greetingProps.font}
                        backgroundColors={this.props.backgroundColors}
                        tabsActiveContentHandler={this.tabsActiveContentHandler}
                        active={this.state.activeTabsContent}
                    />
                  </div>
                  <div className="col-lg-8 p-0">
                    <Greeting {...greetingProps} />
                  </div>
                </div>
                <p className="text_sm pt-2 pt-sm-4 text-center w-100">
                  Ви можете змінити текст привітатто, натиснувши на нього
                </p>
                <Button toggle="modal" target="#PostCardModal" value='Відправити Тостіні'/>
                <p onClick={this.regenerateText} className='text_sm w-100 pt-3 underline cursor-pointer'>Згенерувати новий текст привітатто</p>
              </div>
              <SendPostCard
                sendEmail={this.sendEmail}
                shareOnFacebookUrl={this.getShareOnFacebookUrl()}
                downloadImageUrl={this.getDownloadImageUrl()}
                showSuccessHandler={this.showSuccessHandler}
                openCategoriesStep={this.props.openCategoriesStep}
                unsafeRemoveDialogClassFromBody={this.unsafeRemoveDialogClassFromBody}
            />
              { showSuccess ? <ThanksPopup openCategoriesStep={this.props.openCategoriesStep} showSuccessHandler={this.showSuccessHandler}/> : null }
            </Fragment>
        }
      </section>
    );
  }
}
