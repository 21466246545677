import React, {Component} from "react";
import './Input.css';

export default class Input extends Component {

    state={
        focus:false
    };

    isInvalid = (valid, touched, shouldValidate) => {
        return !valid && touched && shouldValidate
    };

    renderLabel = () =>{
        this.setState({focus:true})
    };
    renderBlurLabel = () =>{
        this.setState({focus:false})
    };


    render() {
        let {type, label, value, onChange, placeholder} = this.props;
        let inputType = type || 'text';
        let htmlFor = `${inputType}-${Math.random()}`;
        return(
            <div className={'position-relative input-wrap'}>
                {this.state.focus ? <label className='default_label' htmlFor={htmlFor}>{label}</label>:''}
                <input
                    className='input'
                    placeholder={placeholder}
                    id={htmlFor}
                    value={value}
                    type={inputType}
                    onChange={onChange}
                    onFocus={this.renderLabel}
                    onBlur={value.length<=0?this.renderBlurLabel:null}
                />
            </div>
        )
    }
}

