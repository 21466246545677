import React, {Component} from "react";
import './Tabs.css'
import backgroundColorTabs from '../../../image/Icons/Fiorelli_Background color.svg'
import fontColorTabs from '../../../image/Icons/Fiorelli_text color.svg'
import fontStyleTabs from '../../../image/Icons/Fiorelli_text style.svg'
import backgroundImageTabs from '../../../image/Icons/Fiorelli_Background illustration.svg'



const tabs = [
    {icon:backgroundColorTabs, name:'Колір фону'},
    {icon:backgroundImageTabs, name:'Фонова ілюстрація'},
    {icon:fontStyleTabs, name:'Стиль тексту'},
    {icon:fontColorTabs, name:'Колір тексту'},
]
export default class Tabs extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
    let {activeTabs, tabsChangeHandler } = this.props;
        return (
            <ul className='tabs_list'>
                {tabs.map((item, idx) => <li onClick={() => tabsChangeHandler(idx + 1)} className={'tabs ' + (activeTabs === idx + 1 ? 'active' : '')} key={idx}>
                    <img src={item.icon} alt=""/>
                    <p className='text_sm'>{item.name}</p>
                </li>)}
            </ul>
        );
    }
}