import React, {Component} from "react";
import './Postcard.css'
import { IMAGES_ROOT } from '../../../constants'
import classnames from 'classnames'


export default class Postcard extends Component{
  changeTitle = (e) => {
    const { changeTitle } = this.props
    if (typeof changeTitle === 'function') {
      changeTitle(e.target.value)
    }
  }

  changeText = (e) => {
    const { changeText } = this.props
    if (typeof changeText === 'function') {
      changeText(e.target.value)
    }
  }

  onImageDrag = (e) => {
    e.preventDefault()
    return false
  }

  render() {
      const {
        backgroundImageUrl,
        backgroundColor,
        foregroundColor,
        font,
        title,
        text,
        mode
      } = this.props;

      const containerStyle = {
        backgroundColor:backgroundColor,
        // backgroundImage: backgroundImageUrl ? `url(${IMAGES_ROOT + backgroundImageUrl})` : '' ,
        fontFamily: font + ", sans-serif",
        color: foregroundColor
      }

      return (
        <div className={classnames({postcard_wrapper: true, 'mode-image': mode === 'image'})}>
          <div
            className="postcard"
            style={containerStyle}
          >
            <div
              className="postcard_background"
              onDragStart={this.onImageDrag}
              onDrag={this.onImageDrag}
              onDragEnd={this.onImageDrag}
              draggable={false}
              style={{userSelect: 'none'}}
            >
              <img src={IMAGES_ROOT + backgroundImageUrl} alt="" />
            </div>
            <div className="postcard_content">
              <input className={font} type="text" value={title} onChange={this.changeTitle} />
              <textarea className={font} rows="4" value={text} onChange={this.changeText} />
            </div>
          </div>
        </div>
      );
  }
}
