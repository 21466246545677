import React, { Component } from 'react'
import { IMAGES_ROOT } from '../../constants'
import './CategoriesScreen.scss'
import buble_pruvitatto from "../../image/buble/Pruvitatto.svg";
import buble_lubini from "../../image/buble/Lubini.svg";

export default class CategoriesScreen extends Component {
  render() {
    const { categories, setCategory } = this.props

    return (
      <section className="category">
        <div className='content'>
          <h2>З чим Ви хочете <span><img className='hoverImage' src={buble_pruvitatto} alt=""/> привітатто </span> близьку людину, яку Ви сильно <span><img className='hoverImage' src={buble_lubini} alt=""/> любіні </span></h2>
          <ul className='row category_list'>
            {categories.map((category,idx) => (
              <li className='col-6 col-md-3 category_item' key={idx}>
                <div className='category_item_link' onClick={() => setCategory(category)}>
                  <img width='100%' src={IMAGES_ROOT + category.image_url} alt=""/>
                </div>
              </li>
            ))}
          </ul>
          <p className='bottom_text'>Згенеруйте тост, обравши потрібну тематику </p>
        </div>
      </section>
    );
  }
}
