import React from "react";
import './Popup.css'
import fb from '../../image/Fiorelli_facebook.svg'
import email from '../../image/Fiorelli_email.svg'
import downnload from '../../image/Fiorelli_Jpeg.svg'
import Button from "../UI/Button/Button";


function ChooseCategory() {
    return(
        <div>
            <div className="modal" id="exampleModalCentered" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h2>
                                ваше <span>тостіні</span> можна
                            </h2>
                            <div className="social_card">
                                <div className="social_link_item">
                                    <img src={fb} alt=""/>
                                    <p className='text_sm'>опублікувати у facebook</p>
                                </div>
                                <div className="social_link_item">
                                    <img src={email} alt=""/>
                                    <p className='text_sm'>відправити поштою</p>
                                </div>
                                <div className="social_link_item">
                                    <img src={downnload} alt=""/>
                                    <p className='text_sm'>скачати <br/> JPEG</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChooseCategory