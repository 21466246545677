import React from "react";
import './Loader.css'
import gif from '../../../image/Fiorelli_creation_default.gif'

function LoaderDefault(){
    return(
        <div className='loader'>
            <img src={gif} alt="loading..."/>
        </div>
    )
}

export default LoaderDefault