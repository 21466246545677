import React, {Fragment} from "react";
import './Popup.css'
import tick from '../../image/Icons/tick_icon.svg'
import close from "../../image/Icons/Fiorelli_close.svg";



function ThanksPopup(props) {
    let { showSuccessHandler, openCategoriesStep } = props;
    return(
        <div className='thanks_wrap'>
            <div className="modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button onClick={showSuccessHandler} className="close">
                            <img src={close} alt=""/>
                        </button>
                        <div className="modal-body text-center">
                            <img src={tick} alt=""/>
                            <h2>
                                ваше <span>тостіні</span> успішно відправлено
                            </h2>
                            <div onClick={openCategoriesStep} className='button'>
                                Створити ще
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ThanksPopup