import React from "react";
import './TabsItem.css'

function TabsBgColor(props) {
    let {backgroundColors, active, tabsActiveContentHandler} = props
    return(
        <div className='d-flex flex-wrap justify-content-center p-3 pt-lg-4'>
            {backgroundColors && backgroundColors.map((item, idx) => <div onClick={()=>tabsActiveContentHandler(item,"backgroundColor")} className={'item color_item ' + (active===item ? 'active' : '')} style={{ backgroundColor: item}} key={idx}>
            </div> )}
        </div>
    )
}
export default TabsBgColor