import React, {Fragment} from "react";
import TabsBgColor from "./TabsContentItem/TabsBgColor";
import TabsBgImage from "./TabsContentItem/TabsBgImage";
import TabsFont from "./TabsContentItem/TabsFont";
import TabsFontColor from "./TabsContentItem/TabsFontColor";

function TabsContent(props) {
    let {activeTabs, foregroundColors, backgroundImages, font, backgroundColors, active, tabsActiveContentHandler} = props
    return(
        <div className='overflow-auto'>
            {
            activeTabs === 1 ? <TabsBgColor backgroundColors={backgroundColors} tabsActiveContentHandler={tabsActiveContentHandler} active={active}/>:
            activeTabs === 2 ? <TabsBgImage backgroundImages={backgroundImages} tabsActiveContentHandler={tabsActiveContentHandler} active={active}/>:
            activeTabs === 3 ? <TabsFont font={font} tabsActiveContentHandler={tabsActiveContentHandler} active={active}/>:
            activeTabs === 4 ? <TabsFontColor foregroundColors={foregroundColors} tabsActiveContentHandler={tabsActiveContentHandler} active={active}/>
             : null
            }
        </div>
    )
}
export default TabsContent