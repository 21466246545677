import React, {Component} from 'react';
import './ChooseNameScreen.scss'
import Button from "../../components/UI/Button/Button";
import is from 'is_js'
import Input from "../../components/UI/Input/Input";
import arrow from '../../image/Icons/Fiorelli_drop down.svg'
import ChooseCategory from "../../components/popup/ChooseCategory";

export default class ChooseNameScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormValid: false,
      formControls: {
        name: {
          value: '',
          type: 'text',
          label: "Ім'я",
          placeholder:"Введіть ім'я",
          touched: false,
        }
      }
    }
  }

  submitHandler = (e) =>{
    e.preventDefault()
    const title = this.state.formControls.name.value
    this.props.setTitle(title)
  };


  onChangeHandler = (e, controlName) => {
    const formControls = { ...this.state.formControls};
    const control = { ...formControls[controlName] };

    control.value = e.target.value;
    formControls[controlName] = control;

    this.setState({
      formControls
    })
  };

  renderInputs = () =>{
    return Object.keys(this.state.formControls).map((controlName, idx) =>{
      const control = this.state.formControls[controlName];
      return(
        <Input
          key={controlName + idx}
          type={control.type}
          value={control.value}
          placeholder={control.placeholder}
          touched={control.touched}
          label={control.label}
          onChange={e => this.onChangeHandler(e, controlName)}
        />
      )
    })
  };

  render(){
    const { categoryName } = this.props

    return (
      <section className="choose_name">
        <ChooseCategory/>
        <div className='content container'>
          <h2> кого Ви хочете привітатто</h2>
          <h2 className="category_name_wrap">
            <div className="category_name" onClick={this.props.openCategoriesStep}>
              <span>{categoryName}</span> <span><img src={arrow} alt=""/></span>
            </div>
          </h2>
          <form onSubmit={this.submitHandler}>
            { this.renderInputs()}
          </form>

          <div onClick={this.submitHandler} style={{cursor: 'pointer'}} className='button'>
            <span className='sangBleu_sunrise_regular'>
              Згенеруватто Тостіні
            </span>
          </div>

        </div>
      </section>
    );
  }
}
