import React, { Component } from "react";
import './Popup.css'
import close from "../../image/Icons/Fiorelli_close.svg";



export default class SendEmail extends Component {
    constructor(props) {
      super(props)

      this.state = {
        receiverEmail: '',
        senderName: ''
      }
    }

    onSubmit = (e) => {
      e.preventDefault()

      const { receiverEmail, senderName } = this.state
      this.setState({receiver_email: ''})
      this.props.sendEmail(receiverEmail, senderName)
    }

    setReceiverEmail = (e) => {
      this.setState({receiverEmail: e.target.value})
    }

    setSenderName = (e) => {
      this.setState({senderName: e.target.value})
    }

    render() {
        const { receiverEmail, senderName } = this.state

        return(
            <div>
                <div className="modal" id="sendEmail" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src={close} alt=""/>
                            </button>
                            <div className="modal-body">
                                <h2>
                                    куди відправити <span>тостіні</span>
                                </h2>
                                <form onSubmit={this.onSubmit}>
                                    <input onChange={this.setReceiverEmail} value={receiverEmail} className='input input_sm' placeholder='Введіть ел. скриньку *' required type="email"/>
                                    <input onChange={this.setSenderName} value={senderName} className='input input_sm' placeholder="Введіть ім'я відправника" required type="text"/>
                                    {/*<Button data-toggle="modal" data-target="#sendEmail" value='Відправити'/>*/}
                                    <button onClick={this.onSubmit} type='submit' className='button'>
                                        Відправити
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
