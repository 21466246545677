import React, { Component } from 'react'
import query_string from 'query-string'
import Greeting from '../components/UI/Postcard/Postcard.js'
import { GREETING_IMAGE_WIDTH, GREETING_IMAGE_HEIGHT } from '../constants'

export default class FullPageGreetingScreen extends Component {
  render() {
    const params = query_string.parse(this.props.location.search)
    const greetingProps = {
      backgroundImageUrl: params.background_image_url,
      backgroundColor: params.background_color,
      foregroundColor: params.foreground_color,
      font: params.font,
      title: params.title,
      text: params.text,
      mode: 'image'
    }


    return (
      <div style={{width: GREETING_IMAGE_WIDTH, height: GREETING_IMAGE_HEIGHT}}>
        <Greeting {...greetingProps} />
      </div>
    )
  }
}
