import React from "react";
import './Button.css'
import {Link} from "react-router-dom";

function Button(props){
    let {value, toggle, target, link} = props;
    return(
        <div data-toggle={toggle} data-target={target} className='button'>
            {link?
            <Link to={link} className='sangBleu_sunrise_regular'>
                {value}
            </Link>:value}
        </div>
    )
}
export default Button