import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import CategoriesScreen from './CategoriesScreen/CategoriesScreen.js'
import ChooseNameScreen from './ChooseNameScreen/ChooseNameScreen.js'
import EditorScreen from './EditorScreen/EditorScreen.js'
import Loader from "../components/UI/Loader/Loader";
import Logo from "../components/UI/Logo/Logo";
import LoaderDefault from "../components/UI/Loader/LoaderDefault";

const STEPS = {
  categories: 'categories',
  name: 'name',
  editor: 'editor'
}

const mapStateToProps = (state, ownProps) => {
  return {
    data: state.data,
    loaded: state.data !== null
  }
};

class WizardScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: STEPS.categories,
      category: undefined,
      title: undefined,
    }
  }

  renderPreloader = () => {
    return <LoaderDefault />
  }

  renderCategoriesStep = () => {
    return (
      <CategoriesScreen
        categories={this.props.data.categories}
        setCategory={this.setCategory}
      />
    )
  }

  renderNameStep = () => {
    return (
      <ChooseNameScreen
        categoryName={this.state.category.name}
        setTitle={this.setTitle}
        openCategoriesStep={this.openCategoriesStep}
      />
    )
  }

  renderEditorStep = () => {
    return (
      <EditorScreen
        category={this.state.category}
        title={this.state.title}
        backgroundColors={this.props.data.background_colors}
        foregroundColors={this.props.data.foreground_colors}
        pledges={this.state.category.pledges}
        backgroundImages={this.state.category.background_images}
        openCategoriesStep={this.openCategoriesStep}
      />
    )
  }

  renderStep = (stepName) => {
    if (stepName === STEPS.categories) {
      return this.renderCategoriesStep()
    }
    if (stepName === STEPS.name) {
      return this.renderNameStep()
    }
    if (stepName === STEPS.editor) {
      return this.renderEditorStep()
    }
  }

  render() {
    if (!this.props.loaded) {
      return this.renderPreloader()
    }

    return (
      <Fragment>
        <Logo onClick={this.openCategoriesStep} />
        {this.renderStep(this.state.step)}
      </Fragment>
    )
  }

  setCategory = (category) => {
    this.setState({category, step: STEPS.name})
  }

  setTitle = (title) => {
    this.setState({title, step: STEPS.editor})
  }

  openCategoriesStep = () => {
    this.setState({step: STEPS.categories})
  }
}

export default connect(mapStateToProps)(WizardScreen)
